<template>
    <b-modal v-model="exibe"
             body-class="modal-vaga-custom-body"
             hide-footer centered
             title="Vaga"
             size="lg">
        <template v-slot:default="{ cancel, ok }">
            <form id="modal-vaga-form"
                  @submit.prevent="salvar(ok)">
                <div class="container">
					<fieldset :disabled="readonly">
						<div class="row">
							<div class="col-sm">
								<div>
									<input-select class="mb-1" label="Status" v-model.trim="status"
												  :opcoes="[{text: 'Aberta', value: 0}, {text: 'Fechada', value: 1}, {text: 'Cancelada', value: 2}]" />
								</div>
								<input-padrao class="mb-1" label="Link vaga" v-model.trim="link" />
								<input-padrao class="mb-1" tipo="number" label="Quantidade de Vagas" v-model.trim="qnt_vagas" />


								<input-padrao class="mb-1" label="Localização" v-model.trim="localizacao" />
								<div style="font-size: 12px; margin-bottom: 7px;">
									<input-text-area class="mb-1" label="Observações" v-model.trim="observacoes" />
								</div>
								<!--<div class="container-anexos">
				<div class="d-flex mb-1">
					<span class="mr-auto">Anexos</span>
					<InputArquivo @upload="adicionarAnexo" />
				</div>
				<div class="caixa-conteudo caixa-lista-anexos">
					<div class="row-anexo"
						 v-for="(anexo, i) in anexos"
						 :key="i">
						<a :href="identificarUrlAnexo(anexo)"
						   :download="anexo"
						   style="font-size: 10px;">
							<BotaoIconeDinamico classeIcone="fas fa-paperclip" size=".7rem" />
							{{ anexo | maxTextLength }}
						</a>
						<BotaoIconeDinamico class="float-right"
											classeIcone="fas fa-trash-alt"
											size=".7rem"
											@click="removerAnexo(anexo)" />
					</div>
				</div>
			</div>-->

							</div>
							<div class="col-sm">
								<div>
									<label class="w-100 m-0 h6 font-size-label-input-select">Cliente</label>
									<select class="w-100 input-select"
											v-model="clienteSelecionadoId">
										<option selected hidden :value="''">Selecione...</option>
										<option v-for="(opc, i) in listaClientes" v-bind:key="i" :value="opc.value">
											{{ opc.text ? opc.text : opc.value }}
										</option>
									</select>
								</div>
								<span id="espacador" style="margin-top:4px;display:block;"></span>
								<input-padrao class="mb-1" label="Título da vaga" v-model.trim="nome" />

								<input-padrao class="mb-1" label="Salário" v-model.trim="salario" />
								<input-padrao class="mb-1" label="Benefício" v-model.trim="benificio" />




							</div>
						</div>
						<div class="row">
							<div class="col-sm">
								<b-form-group label="Visibilidade" class="grupos_radios">
									<b-form-radio v-model="visibilidade" name="visibilidade_vaga" value="0">Pode divulgar o nome da empresa</b-form-radio>
									<b-form-radio v-model="visibilidade" name="visibilidade_vaga" value="1">Não pode divulgar o nome da empresa</b-form-radio>
								</b-form-group>

								<b-form-group label="Categoria" class="grupos_radios">
									<b-form-radio v-model="categoria_vaga" name="categoria_vaga" value="back_end">Back-End</b-form-radio>
									<b-form-radio v-model="categoria_vaga" name="categoria_vaga" value="front_end">Front-End</b-form-radio>
									<b-form-radio v-model="categoria_vaga" name="categoria_vaga" value="devops">DevOps</b-form-radio>
									<b-form-radio v-model="categoria_vaga" name="categoria_vaga" value="infra">Infraestrutura</b-form-radio>
									<b-form-radio v-model="categoria_vaga" name="categoria_vaga" value="outros">Outros</b-form-radio>
								</b-form-group>

								<b-form-group label="Modelo de trabalho" class="grupos_radios">
									<b-form-radio v-model="modelo_vaga" name="radio_modelo" value="remoto">Remoto</b-form-radio>
									<b-form-radio v-model="modelo_vaga" name="radio_modelo" value="presencial">Presencial</b-form-radio>
									<b-form-radio v-model="modelo_vaga" name="radio_modelo" value="hibrido">Híbrido</b-form-radio>
								</b-form-group>

								<b-form-group label="Formato" class="grupos_radios">
									<b-form-radio v-model="formato_vaga" name="radio_formato" value="full">Full Time</b-form-radio>
									<b-form-radio v-model="formato_vaga" name="radio_formato" value="part">Part Time</b-form-radio>
								</b-form-group>

							</div>
							<div class="col-sm">
								<b-form-group label="Tipo de vaga" class="grupos_radios">
									<b-form-radio v-model="tipo_vaga" name="radio_tipo" value="hunting">Hunting</b-form-radio>
									<b-form-radio v-model="tipo_vaga" name="radio_tipo" value="alocacao">Alocação</b-form-radio>
								</b-form-group>

								<b-form-group label="Tipo de contrato" class="grupos_radios">
									<b-form-radio v-model="contrato_vaga" name="radio_contrato" value="clt">CLT</b-form-radio>
									<b-form-radio v-model="contrato_vaga" name="radio_contrato" value="pj">PJ</b-form-radio>
									<b-form-radio v-model="contrato_vaga" name="radio_contrato" value="associado">Associado</b-form-radio>
									<b-form-radio v-model="contrato_vaga" name="radio_contrato" value="cooperado">Cooperado</b-form-radio>
								</b-form-group>


								<b-form-group label="Nível" class="grupos_radios">
									<b-form-radio v-model="nivel_vaga" name="radio_nivel" value="estagio">Estágio</b-form-radio>
									<b-form-radio v-model="nivel_vaga" name="radio_nivel" value="junior">Júnior</b-form-radio>
									<b-form-radio v-model="nivel_vaga" name="radio_nivel" value="pleno">Pleno</b-form-radio>
									<b-form-radio v-model="nivel_vaga" name="radio_nivel" value="senior">Sênior</b-form-radio>
									<b-form-radio v-model="nivel_vaga" name="radio_nivel" value="especialista">Especialista</b-form-radio>
								</b-form-group>
							</div>
						</div>
					</fieldset>
                    
                    <div v-if="!readonly" class="row py-2 mt-2">
                        <div class="col-6">
                            <ul class="modal-cliente-lista-erros">
                                <li v-for="(erro, i) in erros" :key="i">{{ erro }}</li>
                            </ul>
                        </div>
                        <div class="col-6">
                            <botao-modal-ok type="submit" class="float-right ml-2">
                                Salvar
                            </botao-modal-ok>
                            <botao-modal-cancel type="button" class="float-right ml-2" @click="cancel()"> Cancelar </botao-modal-cancel>
                        </div>
                    </div>

                </div>
            </form>

        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">

            </div>
        </template>

    </b-modal>
</template>

<script>
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue';
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue';
    import BotaoIconeDinamico from '@/components/botoes/BotaoIconeDinamico.vue';
    import InputPadrao from '@/components/inputs/InputPadrao.vue';
    import InputTextArea from '@/components/inputs/InputTextArea.vue';
    import InputSelect from '@/components/inputs/InputSelect.vue';
    import InputMoney from '@/components/inputs/InputMoney.vue';
    import InputArquivo from '@/components/inputs/InputArquivo.vue';

    import ClienteServices from '@/assets/js/services/ClienteServices.js'
    import VagaServices from '@/assets/js/services/VagaServices.js'
    import DiscoVirtualServices from '@/assets/js/services/DiscoVirtualServices.js'

    export default {
        name: 'ModalVaga',

        components: {
            BotaoModalOk,
            BotaoModalCancel,
            BotaoIconeDinamico,
            InputPadrao,
            InputTextArea,
            InputSelect,
            InputMoney,
            InputArquivo
        },

        props: {
            vaga: {
                type: Object,
                default: null
			},
			readonly: false
        },

        data: function () {
            return {
                exibe: false,
                erros: [],

                id: 0,
                status: 0,
                nome: '',
                modeloContratacao: '',

                link: '',
                observacoes: '',
                anexos: [],
                clienteSelecionadoId: '',
                usuarioSelecionadoId: '',
                candidatoAprovado: false,
                candidato: { Nome: '', Telefone: '', Email: '', PretensaoSalarial: 0 },
                listaClientes: [],
                novosAnexos: [],
                visibilidade: '',
                tipo_vaga: '',
                categoria_vaga: '',
                formato_vaga: '',
                modelo_vaga: '',
                requisitos: '',
                salario: '',
                localizacao: '',
                soft_skill: '',
                habilidade: '',
                qnt_vagas: '',
                benificio: '',
                contrato_vaga: '',
				nivel_vaga: ''
            }
        },

        computed: {
            opcoesUsuariosRecrutadores() {
                const recrutadores = this.$store.getters['usuarios/listaUsuarios']
                if (recrutadores) {
                    const usuarioPerfilRecrutador = 6
                    return recrutadores
                        .filter(usuario => usuario.Perfil === usuarioPerfilRecrutador)
                        .map(recrutador => { return { value: recrutador.Id, text: recrutador.NomeReduzido } })
                }
                return []
            }
        },

        filters: {
            maxTextLength(value) {
                const lengthLimit = 55;
                const lengthToSlice = value.length - (lengthLimit - 3)
                if (value.length > lengthLimit) {
                    return `...${value.slice(lengthToSlice, value.length)}`
                }
                return value
            }
        },

        methods: {
            show() {
                this.$store.dispatch('usuarios/buscaUsuarios')
                this.buscaListaClientes();
                this.id = this.vaga?.Id ?? 0
                this.status = this.vaga?.Status ?? 0
                this.nome = this.vaga?.Nome


                this.link = this.vaga?.Link
                this.qnt_vagas = this.vaga?.QntVagas
                this.soft_skill = this.vaga?.SoftSkills
                this.localizacao = this.vaga?.Localizacao
                this.requisitos = this.vaga?.Requisitos
                this.visibilidade = this.vaga?.Visibilidade
                this.categoria_vaga = this.vaga?.Categoria
                this.modelo_vaga = this.vaga?.ModeloTrabalho
                this.formato_vaga = this.vaga?.Formato
                this.habilidade = this.vaga?.Habilidade
                this.salario = this.vaga?.FaixaSalarial
                this.benificio = this.vaga?.Beneficio
                this.tipo_vaga = this.vaga?.TipoVaga
                this.contrato_vaga = this.vaga?.TipoContrato
                this.nivel_vaga = this.vaga?.Nivel
                this.observacoes = this.vaga?.Observacoes
                this.modeloContratacao = this.vaga?.ModeloContratacao
                this.faixaSalarial = this.vaga?.FaixaSalarial
                
                this.anexos = this.vaga?.Anexos?.split(',').filter(Boolean) ?? []
                this.clienteSelecionadoId = this.vaga?.ClienteId
                this.usuarioSelecionadoId = this.vaga?.UsuarioId
                this.candidatoAprovado = this.vaga?.CandidatoAprovado
               

                this.novosAnexos = []

                this.exibe = true
            },
            hide() {
                this.exibe = false
            },
            salvar(ok) {
                if (this.novosAnexos.length) {
                    DiscoVirtualServices.uploadFiles(this.novosAnexos.map(x => x.arquivo))
                    this.novosAnexos = []
                }
                const vaga = {
                    ...this.vaga,
                    Status: this.status,
                    Link: this.link,
                    QntVagas: this.qnt_vagas,
                    SoftSkills: this.soft_skill,
                    Localizacao: this.localizacao,
                    Requisitos: this.requisitos,
                    Visibilidade: this.visibilidade,
                    Categoria: this.categoria_vaga,
                    ModeloTrabalho: this.modelo_vaga,
                    Formato: this.formato_vaga,
                    Nome: this.nome,
                    Habilidade: this.habilidade,
                    FaixaSalarial: this.salario,
                    Beneficio: this.benificio,
                    Observacoes: this.observacoes,
                    TipoVaga: this.tipo_vaga,
                    TipoContrato: this.contrato_vaga,
                    Nivel: this.nivel_vaga,
                    ClienteId: this.clienteSelecionadoId

                }

                //const vaga = {
                //    ...this.vaga,
                //    Status: this.status,
                //    Nome: this.nome,
                //    ModeloContratacao: this.modeloContratacao,
                //    FaixaSalarial: this.faixaSalarial,
                //    Link: this.link,
                //    Observacoes: this.observacoes,
                //    Anexos: this.anexos.filter(Boolean).join(','),
                //    UsuarioId: this.usuarioSelecionadoId,
                //    ClienteId: this.clienteSelecionadoId,
                //    Candidato: this.candidato
                //}

                
                this.$emit('salvar', vaga)
                ok()
            },
            buscaListaClientes() {
                ClienteServices.clientesPadrao()
                    .then(response => {

                        this.listaClientes = response.map(cliente => ({
                            value: cliente.Id,
                            text: cliente.NomeFantasia
                        }))
                    })
                    .catch(erro => console.log(erro))
            },
            adicionarAnexo(arquivo, urlTemp) {
                this.novosAnexos.push({ name: arquivo.name, arquivo, urlTemp })
                this.anexos.push(arquivo.name)
            },
            identificarUrlAnexo(nomeAnexo) {
                const anexoEncontrado = this.novosAnexos.find(x => x.name === nomeAnexo)
                return anexoEncontrado?.urlTemp ?? `DiscoVirtual/${nomeAnexo}`
            },
            removerAnexo(nomeAnexo) {
                if (confirm("Você deseja remover o arquivo anexado (" + nomeAnexo + ")?")) {
                    this.anexos = this.anexos.filter(name => name !== nomeAnexo)
                    this.novosAnexos = this.novosAnexos.filter(x => x.name !== nomeAnexo)
                }
            },
            async aprovarCandidato(vagaId) {
                if (!confirm('Confirme a aprovação deste candidato'))
                    return

                await VagaServices.aprovarCandidato(vagaId);
                await ClienteServices.updatePagamento(this.vaga?.ClienteId, {})

                setTimeout(() => { this.$emit('update') }, 1000)
            }
        },

        created() {
            
            
        }

    }
</script>

<style scoped>

    .modal-cliente-custom-body {
        padding: 0px 15px !important;
    }

    .app-modal-custom-footer {
        padding: 0 !important;
    }

    .modal-cliente-tabela {
        height: calc(100vh - 115px);
    }

    .modal-cliente-lista-erros {
        color: red;
    }

    .caixa-lista-anexos {
        background-color: var(--cinza-2);
        height: 100px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .caixa-conteudo {
        border: 1px solid var(--cinza-4);
        width: 100%;
    }

    .row-anexo {
        height: 30px;
    }

        .row-anexo:hover {
            background-color: var(--cinza-3);
        }

    .grupos_radios {
        font-size: 12px;
    }

    .font-size-label-input-select {
        font-size: 12px;
        text-align: start !important;
    }

    .input-select {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
        height: 28px;
    }

        .input-select:focus {
            outline: 0;
        }
</style>